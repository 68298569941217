<!-- start testmonials section -->
<section theme class="what-our-client-wrapper">
    <div class="container">
        <div class="section-heading">
            <h3>What Our Clients Say</h3>
        </div>
        <carousel [interval]="6000">
            <slide class="animated-carousel fadeIn">
                <img src="../../../../assets/images/img1-75x75.png" alt="" class="rounded-circle border">
                <h4 class="padding-20px-top font-size16 letter-spacing-1 no-margin-bottom">Ryan Huoston</h4>
                <h6 class="quote-c no-margin font-size11 text-uppercase letter-spacing-2 padding-25px-bottom">Networking Lead
                </h6>
                <p class="line-height-28 width-50 sm-width-75 xs-width-90 center-col">Hovtech has saved me on many
                    occasions with the ability to create a high-quality product in a crunch. Their skills and awareness
                    of emerging technologies have proven invaluable.</p>
                <span class="quote-c font-size24 vertical-align-top display-inline-block"><i
                        class="fas fa-quote-right"></i></span>
            </slide>
            <slide class="animated-carousel fadeIn">
                <img src="../../../../assets/images/img2-75x75.png" alt="" class="rounded-circle border">
                <h4 class="padding-20px-top font-size16 letter-spacing-1 no-margin-bottom">William Porter</h4>
                <h6 class="quote-c no-margin font-size11 text-uppercase letter-spacing-2 padding-25px-bottom">App Developer</h6>
                <p class="line-height-28 width-50 sm-width-75 xs-width-90 center-col">Thanks for being our de facto tech
                    god two years running! We appreciate you taking time to help us with our website branding. We
                    couldn’t do what we do without all this love from our vendors, so thank you!</p>
                <span class="quote-c font-size24 vertical-align-top display-inline-block"><i
                        class="fas fa-quote-right"></i></span>
            </slide>
            <slide class="animated-carousel fadeIn">
                <img src="../../../../assets/images/img3-75x75.png" alt="" class="rounded-circle border">
                <h4 class="padding-20px-top font-size16 letter-spacing-1 no-margin-bottom">Shawn Lingard </h4>
                <h6 class="quote-c no-margin font-size11 text-uppercase letter-spacing-2 padding-25px-bottom">Sales Designer
                </h6>
                <p class="line-height-28 width-50 sm-width-75 xs-width-90 center-col">It has been a pleasure working
                    with Hovtech Creative team on our website.
                    We are now live and getting numerous compliments on the design and functionality of the site.
                    The team was very responsive and accommodating to my numerous demands and changes of scope.</p>
                <span class="quote-c font-size24 vertical-align-top display-inline-block"><i
                        class="fas fa-quote-right"></i></span>
            </slide>
            <slide class="animated-carousel fadeIn">
                <img src="../../../../assets/images/img4-75x75.png" alt="" class="rounded-circle border">
                <h4 class="padding-20px-top font-size16 letter-spacing-1 no-margin-bottom">Fabienne Cadieux Schuller
                </h4>
                <h6 class="quote-c no-margin font-size11 text-uppercase letter-spacing-2 padding-25px-bottom">Sales Designer
                </h6>
                <p class="line-height-28 width-50 sm-width-75 xs-width-90 center-col">Krista Lapp was fun, professional
                    and responsive!! She walked through our needs and provided quick and understandable guidance so we
                    could get from an idea to an actual website that drove traffic and helped create relationships with
                    our customers.</p>
                <span class="quote-c font-size24 vertical-align-top display-inline-block"><i
                        class="fas fa-quote-right"></i></span>
            </slide>
        </carousel>
    </div>
</section>
<!-- end testmonials section -->