<!-- start main-wrapper section -->
<div theme class="main-wrapper contact-us-top">

    <!-- start page title section -->
    <section class="page-title-section bg-img cover-background theme-overlay" data-overlay-dark="7">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="contact-us-title">Contact Us</h1>
                </div>
                <div class="col-md-12">
                    <p class="contact-us-wish">Make your business grow with us. We are just a step away!</p>
                </div>
            </div>

        </div>
    </section>
    <!-- end page title section -->

    <!-- start contact info section -->
    <section theme class="contact-us-info">
        <div class="container">

            <div class="row">
                <div class="col-lg-4 col-md-12 sm-margin-20px-bottom contact-us-info-item">
                    <div
                        class="services-block padding-45px-tb padding-25px-lr sm-padding-35px-tb sm-padding-20px-lr xs-padding-30px-tb xs-padding-15px-lr last-paragraph-no-margin wow fadeInUp">
                        <div class="title-box margin-25px-bottom sm-margin-15px-bottom">
                            <i class="fas fa-map-marker-alt text-theme-color"></i>
                            <div class="box-circle-large"></div>
                            <div class="box-circle-small"></div>
                        </div>
                        <div class="contact-us-info-item-label text-uppercase text-small font-weight-600 alt-font margin-5px-bottom">
                            Visit Our Office</div>
                        <p class="center-col">16899 Jamboree Rd
                            <br>Irvine, CA 92606.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 sm-margin-20px-bottom wow fadeInUp contact-us-info-item" data-wow-delay="0.2s">
                    <div
                        class="services-block padding-45px-tb padding-25px-lr sm-padding-35px-tb sm-padding-20px-lr xs-padding-30px-tb xs-padding-15px-lr last-paragraph-no-margin">
                        <div class="title-box margin-25px-bottom sm-margin-15px-bottom">
                            <i class="fas fa-comments text-theme-color"></i>
                            <div class="box-circle-large"></div>
                            <div class="box-circle-small"></div>
                        </div>
                        <div class="contact-us-info-item-label text-uppercase text-small font-weight-600 alt-font margin-5px-bottom">
                            Let's Talk</div>
                        <p class="center-col">Phone:<a class="font-size16 sm-font-size14" href="tel:+1-310-227-2410">
                                +1-310-227-2410</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 wow fadeInUp contact-us-info-item" data-wow-delay="0.4s">
                    <div class="services-block padding-45px-tb padding-25px-lr sm-padding-35px-tb sm-padding-20px-lr xs-padding-30px-tb xs-padding-15px-lr last-paragraph-no-margin">
                        <div class="title-box margin-25px-bottom sm-margin-15px-bottom">
                            <i class="fas fa-envelope text-theme-color"></i>
                            <div class="box-circle-large"></div>
                            <div class="box-circle-small"></div>
                        </div>
                        <div
                            class="contact-us-info-item-label text-uppercase text-small font-weight-600 alt-font margin-5px-bottom">
                            E-mail Us</div>
                        <p class="center-col"><a class="font-size16 sm-font-size14"
                                href="mailto:info@hovtech.com">info@hovtech.com</a></p>
                    </div>
                </div>
                <!-- end -->
            </div>

        </div>
    </section>
    <!-- end contact info section -->
</div>
<!-- end main-wrapper section -->