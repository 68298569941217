import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  host: { class: "about-us" },
  encapsulation: ViewEncapsulation.None

})
export class AboutComponent {
    constructor(){}
}
