import { Theme } from './symbols';

export const darkTheme: Theme = {
  name: 'dark',
  properties: {
    '--background': '#1F2125',
    '--on-background': '#fff',
    '--primary': '#2F90FF',
    '--on-primary': '#fff',
    '--secondary': '#474A4F',
    '--on-secondary': '#000',
    '--surface': '#282A2F',
    '--on-surface': '#dddede',
    '--error': '#E74E3C',
    '--on-error': '#fff',
    '--gray-for-text': '#B0B0B0',
    '--secondary-background': '#1A1C1F',
    '--secondary-border': '#6C6C6C',
    '--for-about-scores': '#d3d3d3',
    '--for-footer-background': '#111111',
    '--for-footer-bar-background': '#0e0e0e',
    '--for-footer-text-color': '#797979',
    '--for-what-we-offer-item-background': '#dadada',
    '--main-color': '#4e54c8',

  }
};
