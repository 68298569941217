<!-- start header section -->
<header theme class="position-absolute width-100 index-header sm-position-relative"
    [ngClass]="{'header-b-c': router.url != '/' }">
    <div class="navbar-default">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-lg-12">
                    <div class="menu_area alt-font">
                        <nav class="navbar navbar-expand-lg  no-padding">

                            <div class="navbar-header navbar-header-custom">
                                <!-- start logo -->
                                <a [routerLink]="['']" class="navbar-brand">
                                    <img id="logo" src="../assets/images/logo.png" alt="logo">
                                </a>
                                <!-- end logo -->
                            </div>

                            <div class="navbar-toggler"></div>

                            <!-- start menu Area -->
                            <ul class="navbar-nav ml-auto" id="nav" style="display: none;">
                                <li>
                                    <a [routerLink]="['']" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">HOME</a>
                                </li>
                                <li><a [routerLink]="['/about']" routerLinkActive="active">ABOUT US</a></li>
                                <li><a [routerLink]="['/services']" routerLinkActive="active">SERVICES</a></li>

                                <li><a [routerLink]="['/contact']" routerLinkActive="active">CONTACT US</a></li>

                                <li class="theme-btn">
                                    <app-theme-btn></app-theme-btn>
                                </li>
                            </ul>
                            <!-- end menu Area -->
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- end header section -->