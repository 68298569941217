
    <!-- start footer section -->
    <footer theme>
        <div class="container footer-top">
            <div class="row margin-30px-bottom padding-30px-bottom border-bottom border-color-light-white">
                <div class="col-lg-4 col-md-12 sm-margin-30px-bottom text-center">
                    <h3 class="footer-title-style text-white">Phone</h3>
                    <a class="font-size16 sm-font-size14" href="tel:+1-310-227-2410"> +1-310-227-2410</a>
                </div>

                <div class="col-lg-4 col-md-12 sm-margin-30px-bottom text-center">
                    <h3 class="footer-title-style text-white">Email</h3>
                    <a class="font-size16 sm-font-size14" href="mailto:info@hovtech.com">info@hovtech.com</a>
                </div>

                <div class="col-lg-4 col-md-12 text-center">
                    <h3 class="footer-title-style text-white">Location</h3>
                    <p class="font-size16 sm-font-size14">16899 Jamboree Rd, Irvine, CA 92606</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-center">
                    <ul class="social-icon-style2 no-margin">
                        <li>
                            <a href="https://www.linkedin.com/company/hovtechinc/about/" target="blank">
                                <i class="fab fa-linkedin-in"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
        <div class="footer-bar">
            <div class="container">
                <p class="xs-font-size13">Hovtech Creative Agency © {{currentYear}} All Rights Reserved.</p>
            </div>
        </div>
    </footer>
    <!-- end footer section -->