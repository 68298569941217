import { Component } from '@angular/core';

@Component({
  selector: 'app-what-our-client-say',
  templateUrl: './what-our-client-say.component.html',
  styleUrls: ['./what-our-client-say.component.scss'],
})
export class WhatOurClientSayComponent {
    constructor(){}
}
