import { Component, OnInit, Inject } from '@angular/core';
import { ThemeService } from '../theme/theme.service';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-theme-btn',
    templateUrl: './theme-btn.component.html',
    styleUrls: ['./theme-btn.component.scss'],
})
export class ThemeBtnComponent implements OnInit {
    private darkThemeSelected: boolean;

    constructor(
        private themeService: ThemeService,
        @Inject(DOCUMENT) private _document) {
        this.darkThemeSelected = (localStorage.getItem('themeSwitch') !== null && localStorage.getItem('themeSwitch') === 'dark');
    }

    public isDark: boolean;

    ngOnInit() {
        this.darkThemeSelected ? this.themeService.setTheme('dark') : this.themeService.setTheme('light');
        this.toggle();
        this.resetTheme();
    }

    toggle() {

        const active = this.themeService.getActiveTheme();

        if (active.name === 'light') {
            this.themeService.setTheme('dark');
            this.isDark = false;
            this._document.body.style.background = '#1F2125';
            localStorage.setItem('themeSwitch', 'dark');

        } else {
            this.themeService.setTheme('light');
            this.isDark = true;
            this._document.body.style.background = '#fff';
            localStorage.setItem('themeSwitch', 'light');

        }
    }


    private resetTheme() {
        if (this.darkThemeSelected) {
            this.themeService.setTheme('dark')
            localStorage.setItem('themeSwitch', 'dark');
            this.isDark = false;

        } else {
            this.themeService.setTheme('light')
            localStorage.setItem('themeSwitch', 'light');
            this.isDark = true;

        }
    };
}
