import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
    constructor(public router: Router) { }

    public navigateTo = (path: string): void => {
        this.router.navigate([path])
    }
}
