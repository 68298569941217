import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';


const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
    },
    {
        path: 'about',
        component: AboutComponent,
    },
    {
        path: 'services',
        component: ServicesComponent,
    },
    {
        path: 'contact',
        component: ContactComponent,
    },
    { path: '**', component: HomeComponent }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
