<!-- start page title section -->
<section theme class="page-title-section bg-img cover-background theme-overlay" data-overlay-dark="7">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="about-us-title">About Us</h1>
            </div>
        </div>

    </div>
</section>
<!-- end page title section -->

<!-- start about section -->
<section theme class="about-hovtech">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12 sm-margin-50px-bottom xs-margin-30px-bottom sm-text-center  wow fadeInUp "
                data-wow-delay="0.2s">
                <img src="../../assets/images/about.png" alt="about" />
            </div>

            <div class="col-lg-5 col-md-12  wow fadeInUp" data-wow-delay="0.2s">
                <div class="padding-50px-left sm-no-padding">
                    <h3 class="sm-margin-lr-auto xs-width-100">About Hovtech</h3>

                    <h4 class="font-size18 xs-font-size16 font-weight-500">Hovtech Creative Agency has rich experience
                        in designing and developing websites for both
                        start-ups and big companies.</h4>
                    <p> We work for you by working with you. We love to build the websites,
                        apps and digital products that transform your ideas into realities. We aim to give first useful
                        insights within reasonable deadlines.</p>
                    <p> Your project may involve a website, a mobile application,
                        an online interface or a clever use of an emerging technology. Whatever the solution, we can
                        help in terms of completeness, rapid digital product development and building dynamic teams and
                        workflows.</p>
                </div>

            </div>
        </div>
    </div>
</section>
<!-- end about section -->

<!-- start about service description -->
<section theme class="about-second">
    <div class="container-fluid padding-three-lr">
        <div class="row">
            <div class="col-xl-4 col-lg-6 col-md-6 md-margin-50px-bottom margin-30px-bottom lg-margin-60px-bottom  wow fadeInUp"
                data-wow-delay="0.2s">
                <div class="padding-five-lr">
                    <span
                        class="icon display-block md-display-inline-block vertical-align-middle margin-10px-bottom md-no-margin"><i
                            class="fas fa-clone text-theme-color font-size36 sm-font-size32 xs-font-size26"></i></span>
                    <h6
                        class="display-inline-block vertical-align-middle no-margin-bottom md-padding-15px-left sm-padding-10px-left">
                        NO PACKAGES, FOR REASONABLE COSTS</h6>
                    <p
                        class="width-90 xs-width-100 margin-10px-top xs-margin-10px-top font-size16 line-height-28 sm-font-size14 sm-line-height-24 no-margin-bottom">
                        We only quote you for what you need, and do not make you commit to a package which may or may
                        not include what you precisely need. Each project’s scope is different, so we assess the project
                        itself, and then give you exact pricing. No project is the same, so no pricing is either.</p>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 md-margin-50px-bottom margin-30px-bottom lg-margin-60px-bottom wow fadeInUp"
                data-wow-delay="0.2s">
                <div class="padding-five-lr">
                    <span
                        class="icon display-block md-display-inline-block vertical-align-middle margin-10px-bottom md-no-margin"><i
                            class="fas fa-toolbox text-theme-color font-size36 sm-font-size32 xs-font-size26"></i></span>
                    <h6
                        class="display-inline-dblock vertical-align-middle no-margin-bottom md-padding-15px-left sm-padding-10px-left">
                        FREE CUSTOMIZED MAINTENANCE</h6>
                    <p
                        class="width-90 xs-width-100 margin-10px-top xs-margin-10px-top font-size16 line-height-28 sm-font-size14 sm-line-height-24 no-margin-bottom">
                        When you purchase one of our websites, we provide free customized instructions on how to create
                        and design your website. This makes it easier to edit content. We also offer maintenance options
                        available too.</p>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6  md-margin-50px-bottom margin-30px-bottom lg-margin-60px-bottom wow fadeInUp"
                data-wow-delay="0.2s">
                <div class="padding-five-lr">
                    <span
                        class="icon display-block md-display-inline-block vertical-align-middle margin-10px-bottom md-no-margin"><i
                            class="fas fa-tachometer-alt text-theme-color font-size36 sm-font-size32 xs-font-size26"></i></span>
                    <h6
                        class="display-inline-block vertical-align-middle no-margin-bottom md-padding-15px-left sm-padding-10px-left">
                        CREATE WEBSITES FAST AND EFFICIENTLY</h6>
                    <p
                        class="width-90 xs-width-100 margin-10px-top xs-margin-10px-top font-size16 line-height-28 sm-font-size14 sm-line-height-24 no-margin-bottom">
                        With every project, we have a project plan and schedule. This ensures we stay on track and it
                        provides transparency to the customer on where we are in the process. We do our best to provide
                        services in a timely manner, so that you’re on track to meeting your business goals.</p>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 md-margin-50px-bottom margin-30px-bottom lg-margin-60px-bottom wow fadeInUp"
                data-wow-delay="0.2s">
                <div class="padding-five-lr">
                    <span
                        class="icon display-block md-display-inline-block vertical-align-middle margin-10px-bottom md-no-margin"><i
                            class="fas fa-lightbulb text-theme-color font-size36 sm-font-size32 xs-font-size26"></i></span>
                    <h6
                        class="display-inline-block vertical-align-middle no-margin-bottom md-padding-15px-left sm-padding-10px-left">
                        FULLY CUSTOMIZED SOLUTIONS</h6>
                    <p
                        class="width-90 xs-width-100 margin-10px-top xs-margin-10px-top font-size16 line-height-28 sm-font-size14 sm-line-height-24 no-margin-bottom">
                        We don’t believe in a one-size-fits-all approach to designing or marketing. From search engine
                        optimization to full brand campaigns including logo design, print collateral and website design,
                        all solutions are 100% customized.</p>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 md-margin-50px-bottom margin-30px-bottom wow fadeInUp"
                data-wow-delay="0.2s">
                <div class="padding-five-lr">
                    <span
                        class="icon display-block md-display-inline-block vertical-align-middle margin-10px-bottom md-no-margin"><i
                            class="fas fa-laptop-code text-theme-color font-size36 sm-font-size32 xs-font-size26"></i></span>
                    <h6
                        class="display-inline-block vertical-align-middle no-margin-bottom md-padding-15px-left sm-padding-10px-left">
                        NEW TECHNOLOGY INTEGRATION
                    </h6>
                    <p
                        class="width-90 xs-width-100 margin-10px-top xs-margin-10px-top font-size16 line-height-28 sm-font-size14 sm-line-height-24 no-margin-bottom">
                        You don’t have to keep up with website technology enhancements. We’ll take care of this for you.
                        We’ll present suggested upgrades, new tools or applications, additional security measures, and
                        further ways to market yourself online. We are your research and development go-to organization.
                    </p>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 md-margin-50px-bottom margin-30px-bottom wow fadeInUp"
                data-wow-delay="0.2s">
                <div class="padding-five-lr">
                    <span
                        class="icon display-block md-display-inline-block vertical-align-middle margin-10px-bottom md-no-margin"><i
                            class="fas fa-headset text-theme-color font-size36 sm-font-size32 xs-font-size26"></i></span>
                    <h6
                        class="display-inline-block vertical-align-middle no-margin-bottom md-padding-15px-left sm-padding-10px-left">
                        OUR HELP DESK IS 24/7</h6>
                    <p
                        class="width-90 xs-width-100 margin-10px-top xs-margin-10px-top font-size16 line-height-28 sm-font-size14 sm-line-height-24 no-margin-bottom">
                        We understand sometimes issues occur and you shouldn’t have to deal with them all alone.
                        Therefore, we have a 24 hour help desk. It doesn’t matter whether you’re struggling to make
                        website edits, are having speed/performance issues, receive errors, need file recovery, etc. we
                        will assist you.</p>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                <div class="padding-five-lr">
                    <span
                        class="icon display-block md-display-inline-block vertical-align-middle margin-10px-bottom md-no-margin"><i
                            class="fas fa-handshake text-theme-color font-size36 sm-font-size32 xs-font-size26"></i></span>
                    <h6
                        class="display-inline-block vertical-align-middle no-margin-bottom md-padding-15px-left sm-padding-10px-left">
                        WE BELIEVE IN LONG-TERM PARTNERSHIPS</h6>
                    <p
                        class="width-90 xs-width-100 margin-10px-top xs-margin-10px-top font-size16 line-height-28 sm-font-size14 sm-line-height-24 no-margin-bottom">
                        We know how valuable a partnership can be in the long run, which is why we always make sure to
                        deliver beyond expectations to make our customers happy.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end aabout service description -->

<app-what-our-client-say></app-what-our-client-say>

<!-- start counter section -->
<section theme class="bg-theme about-us-scores">
    <div class="container">
        <div class="row">
            <div class="col-6 col-lg-3 col-md-6 sm-margin-30px-bottom">
                <div class="counter-box">
                    <span
                        class="icon margin-10px-bottom display-block text-white font-size36 xs-font-size30 xs-text-center">
                        <i class="far fa-user"></i></span>
                    <app-animated-digit [end]="1826"></app-animated-digit>

                    <div class="separator-line-horrizontal-medium-light3  margin-15px-tb xs-margin-10px-tb opacity5 center-col about-us-separator-line">
                    </div>
                    <p class="font-size18 sm-font-size16 font-weight-600 text-white no-margin text-center">Satisfied
                        Visitors</p>
                </div>
            </div>
            <div class="col-6 col-lg-3 col-md-6 sm-margin-30px-bottom">
                <div class="counter-box">
                    <span
                        class="icon margin-10px-bottom display-block text-white font-size36 xs-font-size30 xs-text-center">
                        <i class="far fa-smile"></i></span>
                    <app-animated-digit [end]="875"></app-animated-digit>
                    <div
                        class="separator-line-horrizontal-medium-light3  margin-15px-tb xs-margin-10px-tb opacity5 center-col about-us-separator-line">
                    </div>
                    <p class="font-size18 sm-font-size16 font-weight-600 text-white no-margin text-center">Happy Clients
                    </p>
                </div>
            </div>
            <div class="col-6 col-lg-3 col-md-6">
                <div class="counter-box">
                    <span
                        class="icon margin-10px-bottom display-block text-white font-size36 xs-font-size30 xs-text-center">
                        <i class="fas fa-trophy"></i></span>
                    <app-animated-digit [end]="1412"></app-animated-digit>
                    <div
                        class="separator-line-horrizontal-medium-light3  margin-15px-tb xs-margin-10px-tb opacity5 center-col about-us-separator-line">
                    </div>
                    <p class="font-size18 sm-font-size16 font-weight-600 text-white no-margin text-center">Awards Wining
                    </p>
                </div>
            </div>
            <div class="col-6 col-lg-3 col-md-6">
                <div class="counter-box">
                    <span
                        class="icon margin-10px-bottom display-block text-white font-size36 xs-font-size30 xs-text-center">
                        <i class="far fa-life-ring"></i></span>
                    <app-animated-digit [end]="100"></app-animated-digit>
                    <div
                        class="separator-line-horrizontal-medium-light3  margin-15px-tb xs-margin-10px-tb opacity5 center-col about-us-separator-line">
                    </div>
                    <p class="font-size18 sm-font-size16 font-weight-600 text-white no-margin text-center">Consultation
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end counter section -->

<!-- start team section -->
<section theme class="about-us-team">
    <div class="container">

        <div class="section-heading">
            <h3>Our Creative Team</h3>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12 sm-margin-20px-bottom about-us-team-member">
                <div class="partner-box">
                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            <div class="partner-img"><img alt="partner" src="../../assets/images/teammember-05.jpg">
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <div class="partner-text"><span>Web Designer</span>
                                <h4>Niamah Hower</h4>
                                <p>Consequuntur magni dolores eos qui ratione voluptatem sequi nesciuntconsectetur,
                                    adipisci velit, numquam modi tempora.</p>
                                <ul class="social-icon-style1">
                                    <li>
                                        <a href="javascript:void(0)"><i class="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"><i class="fab fa-twitter"></i></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"><i class="fab fa-google-plus-g"></i></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"><i class="fab fa-linkedin-in"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 about-us-team-member">
                <div class="partner-box">
                    <div class="row">
                        <div class="col-xs-12 col-md-6 col-sm-12">
                            <div class="partner-img"><img alt="partner" src="../../assets/images/teammember-06.jpg">
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-sm-12">
                            <div class="partner-text"><span>Web Developer</span>
                                <h4>Keir Prestonly</h4>
                                <p>Consequuntur magni dolores eos qui ratione voluptatem sequi nesciuntconsectetur,
                                    adipisci velit, numquam modi tempora.</p>
                                <ul class="social-icon-style1">
                                    <li>
                                        <a href="javascript:void(0)"><i class="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"><i class="fab fa-twitter"></i></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"><i class="fab fa-google-plus-g"></i></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"><i class="fab fa-linkedin-in"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end team section -->