
import { Component, Input, OnDestroy } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { switchMap, startWith, scan, takeWhile, takeUntil, mapTo, delay } from 'rxjs/operators';

@Component({
    selector: "app-animated-digit",
    templateUrl: "animated-digit.component.html",
    styleUrls: ["animated-digit.component.scss"]
})

export class AnimatedDigitComponent implements OnDestroy {

    @Input()
    set end(endRange: number) {
        this._counterSub$.next(endRange);

    }

    private countInterval: number;
    public currentNumber = 0;
    private _counterSub$ = new Subject();
    private _onDestroy$ = new Subject();

    constructor() {
        this._counterSub$
            .pipe(
                switchMap(endRange => {
                    if (endRange <= 100) {
                        this.countInterval = 140;
                    } else if (endRange > 100 && endRange <= 875) {

                        this.countInterval = 14;

                    } else if (endRange > 875 && endRange <= 1412) {

                        this.countInterval = 10;

                    } else if (endRange > 1412 && endRange <= 1826) {

                        this.countInterval = 8;

                    }
                    return timer(0, this.countInterval).pipe(
                        mapTo(this.positiveOrNegative(endRange, this.currentNumber)),
                        startWith(this.currentNumber),
                        scan((acc: number, curr: number) => acc + curr),
                        takeWhile(this.isApproachingRange(endRange, this.currentNumber))
                    )
                }),
                takeUntil(this._onDestroy$)
            )
            .subscribe((val: number) => this.currentNumber = val);
    }

    private positiveOrNegative(endRange, currentNumber) {
        return endRange > currentNumber ? 1 : -1;
    }

    private isApproachingRange(endRange, currentNumber) {
        return endRange > currentNumber
            ? val => val <= endRange
            : val => val >= endRange;
    }

    ngOnDestroy() {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }
}
