<!-- start main banner area -->
<div class="banner-area">
    <div class="container">
        <div class="row">

            <!-- start left banner text -->
            <div class="col-lg-6 col-md-12">
                <div class="header-text sm-width-75 xs-width-100">
                    <h1 class="line-height-55 md-line-height-50 xs-line-height-40 wow fadeInUp text-theme-color xs-font-size30"
                        data-wow-delay=".1s">Web and Mobile Development / Graphic Design</h1>
                    <p class="font-size16 line-height-28 xs-font-size14 xs-line-height-26 margin-30px-bottom sm-margin-20px-bottom width-80 xs-width-90 wow fadeInUp"
                        data-wow-delay=".2s">WE ARE A CREATIVE AGENCY SPECIALIZED IN
                        DEVELOPMENT & UI/UX DESIGN
                        <!-- <a href="#" class="btn-play video vertical-align-middle hovtech-video-i">
                            <i class="fa fa-play"></i></a> -->
                    </p>
                </div>
            </div>
            <!-- end banner text -->

            <!-- start right image banner -->
            <div class="col-lg-6 col-md-12 sm-text-center">
                <div class="banner-img">
                    <img src="../../../assets/images/content-01.png" class="img-fluid float-right width-100" alt="">
                </div>
            </div>
            <!-- end right image banner -->
        </div>
    </div>

    <!-- start shape area -->
    <div class="header-shape-bg hvt-wave">
        <img src="../../../assets/images/header-bg-02.png" class="img-fluid width-100" alt="">
    </div>
    <!-- end shape area -->
</div>
<!-- end main banner area -->

<!-- start service section -->
<section theme class="hvt-we-provide">
    <div class="container">
        <div class="text-center section-heading">
            <h2>We Provide Awesome Services</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12 sm-margin-20px-bottom">
                <div class="services-block padding-45px-tb padding-25px-lr sm-padding-35px-tb sm-padding-20px-lr xs-padding-30px-tb xs-padding-15px-lr last-paragraph-no-margin wow fadeInUp"
                    data-wow-delay=".2s">
                    <div class="title-box margin-25px-bottom sm-margin-15px-bottom">
                        <i class="fas fa-clone text-theme-color"></i>
                        <div class="box-circle-large"></div>
                        <div class="box-circle-small"></div>
                    </div>
                    <h3 class="margin-10px-bottom font-size22 md-font-size20 xs-font-size18">Decent & Customizable
                        Development</h3>
                    <p class="font-size16 line-height-28 sm-font-size14 sm-line-height-24">We employ UX-friendly web and
                        mobile strategies to produce a cohesive, and desirable effect on the target audience. We also
                        make sure it is a part of your UI.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 sm-margin-20px-bottom">
                <div class="services-block padding-45px-tb padding-25px-lr sm-padding-35px-tb sm-padding-20px-lr xs-padding-30px-tb xs-padding-15px-lr last-paragraph-no-margin wow fadeInUp"
                    data-wow-delay=".4s">
                    <div class="title-box margin-25px-bottom sm-margin-15px-bottom">
                        <i class="fab fa-accusoft text-theme-color"></i>
                        <div class="box-circle-large"></div>
                        <div class="box-circle-small"></div>
                    </div>
                    <h3 class="margin-10px-bottom font-size22 md-font-size20 xs-font-size18">Responsive Design</h3>
                    <p class="font-size16 line-height-28 sm-font-size14 sm-line-height-24">The success of any website
                        lies on responsive web design to generate higher conversions across desktop, tablet and mobile
                        devices.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="services-block padding-45px-tb padding-25px-lr sm-padding-35px-tb sm-padding-20px-lr xs-padding-30px-tb xs-padding-15px-lr last-paragraph-no-margin wow fadeInUp"
                    data-wow-delay=".6s">
                    <div class="title-box margin-25px-bottom sm-margin-15px-bottom">

                        <i class="fas fa-code text-theme-color"></i>

                        <div class="box-circle-large"></div>
                        <div class="box-circle-small"></div>
                    </div>
                    <h3 class="margin-15px-bottom font-size22 md-font-size20 xs-font-size18">Clean & Powerful Code</h3>
                    <p class="font-size16 line-height-28 sm-font-size14 sm-line-height-24">It is our task to provide you
                        with clean code. Your code will be precise, sufficiently open and understandable for it to
                        really support team work.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end service section -->

<!-- start innovate business section -->
<section theme class="what-we-offer">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12 sm-text-center sm-margin-30px-bottom">
                <img src="../../assets/images/content-02.png" alt="content" />
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="padding-30px-left sm-no-padding">
                    <h4 class="sm-margin-lr-auto sm-text-center xs-width-100 sm-margin-30px-bottom">
                        WHAT WE OFFER
                    </h4>
                    <p>We aim to <span>grow your business </span> and help to take it
                        to the next level!</p>
                    <div id="accordion" class="accordion-style">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne">Web development
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                data-parent="#accordion">
                                <div class="card-body">
                                    <p>We are here to distribute and implement
                                        productive, engaging and reliable web solutions for your business outcomes.</p>
                                    <br>
                                    <p>During the development phase, we’ll develop the
                                        functionalities aligned with design with existing platforms that were carefully
                                        instructed by your company.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <h5 class="mb-0">
                                    <button class="btn btn-link collapsed" data-toggle="collapse"
                                        data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Mobile development
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                data-parent="#accordion">
                                <div class="card-body">
                                    <p>We assist our clients on the full app
                                        development, starting from initial strategy and concept stage to app store
                                        launch.
                                    </p>
                                    <br>
                                    <p>Our process is centered around a flexible
                                        methodology with focus on providing you with full transparency and effective
                                        progress.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <h5 class="mb-0">
                                    <button class="btn btn-link collapsed" data-toggle="collapse"
                                        data-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree">
                                        Web Design
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                data-parent="#accordion">
                                <div class="card-body">
                                    <p>We provide custom website designs with insights
                                        to generate greater brand engagement, higher conversions and measurable results.
                                    </p>
                                    <br>
                                    <p>Each website we create, delivers a one-of-a-kind
                                        interactive experience that not only reflects your company’s vision, but
                                        delivers
                                        solid conversions.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <h5 class="mb-0">
                                    <button class="btn btn-link collapsed" data-toggle="collapse"
                                        data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        Creative Design
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
                                data-parent="#accordion">
                                <div class="card-body">
                                    <p>Creativity starts from a belief to develop an idea. We handle this hard part.
                                        Once you determine how to integrate your business concept, we are ready to go
                                        ahead.</p>
                                    <br>
                                    <p>One of the primary purposes of our design is to add context to the natural
                                        behavior of users and, by doing so, to provide with a story that they can take
                                        from the experience.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFive">
                                <h5 class="mb-0">
                                    <button class="btn btn-link collapsed" data-toggle="collapse"
                                        data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        Logo Design
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive"
                                data-parent="#accordion">
                                <div class="card-body">
                                    <p>Professional logo designs tailored to your brand’s culture, core values and
                                        distinction. A creative logo design can raise awareness of your branding.</p>
                                    <br>
                                    <p>We take a strategic approach to creating unique brand identities, including logo
                                        design. After all, branding is the core of your company to be based on.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end innovate business section -->

<!-- start contact section -->
<section theme class="parallax cover-background hvt-free-to-contact" data-overlay-dark="7">
    <div class="container">
        <div class="row">
            <div class="col-md-9 text-center center-col">
                <h4 class="text-white sm-margin-20px-bottom">Haven't found what you like? feel free to contact</h4>
                <p class="text-white font-size16 xs-font-size14 margin-30px-bottom">We always try to provide you our
                    best business consulting service.</p>
                <!-- <a href="javascript:void(0)" class="butn"><span>Buy Now</span></a> -->
            </div>

        </div>
    </div>
</section>
<!-- end contact section -->

<!-- start OUR WORK PROCESS Mobile section -->
<section theme class="process-mobile">
    <div class="container">
        <div class="section-heading">
            <h3>OUR WORK PROCESS</h3>
        </div>
    </div>
    <div class="container no-padding xs-padding-15px-lr">
        <div class="container">
            <accordion [isAnimated]="true" [closeOthers]="true">
                <accordion-group [isOpen]="false" #groupDefining>
                    <div accordion-heading>
                        <div class="float-right">
                            <span
                                [ngClass]="{'arrow-up-icon ': groupDefining?.isOpen, 'arrow-down-icon ': !groupDefining?.isOpen }"
                                class=" icon-for-accordion "></span>
                        </div>
                        <i class="far fa-chart-bar"></i>
                        <span class="display-block xs-display-inline-block">Defining the problem</span>
                    </div>
                    <div>
                        <div class="container">
                            <div class="row p-t-50">
                                <div class="col-lg-5 sm-margin-20px-bottom xs-margin-15px-bottom sm-text-center">
                                    <img src="../../assets/images/tab-content-01.png" alt="">
                                </div>
                                <div class="col-lg-5 offset-lg-1">

                                    <h5>FAEFAKEFAKE</h5>
                                    <p>Initially, we clarify the priorities of the upcoming project by focusing on key
                                        critical issues. Well-defined problems lead to breakthrough solutions by our
                                        team.
                                    </p>

                                    <ul class="list-style no-margin-bottom">
                                        <li>Exclusive design</li>
                                        <li>Life time supports</li>
                                        <li>Solve your problem with us</li>
                                        <li>We Provide Awesome Services</li>
                                        <li>Your business deserves best software</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </accordion-group>
                <accordion-group [isOpen]="false" #groupPlanning>
                    <div accordion-heading>
                        <div class="float-right">
                            <span
                                [ngClass]="{'arrow-up-icon ': groupPlanning?.isOpen, 'arrow-down-icon ': !groupPlanning?.isOpen }"
                                class=" icon-for-accordion "></span>
                        </div>
                        <i class="fas fa-code"></i>
                        <span class="display-block xs-display-inline-block">Planning the solution</span>
                    </div>
                    <div>
                        <div class="container">
                            <div class="row p-t-50">
                                <div class="col-lg-5 sm-margin-20px-bottom xs-margin-15px-bottom order-lg-1 order-2">
                                    <h5>Let’s build something great FAEFAKEFAKE</h5>
                                    <p>The planning starts from identifying the goals of the project. The outcome is a
                                        documented outline, detailed project plan and a web strategy.</p>

                                    <ul class="list-style no-margin-bottom">
                                        <li>Life time supports</li>
                                        <li>Exclusive design</li>
                                        <li>Solve your problem with us</li>
                                        <li>We Provide Awesome Services</li>
                                        <li>Your business deserves best software</li>
                                    </ul>

                                </div>
                                <div
                                    class="col-lg-5 offset-lg-1 order-lg-2 order-1 sm-text-center sm-margin-20px-bottom">
                                    <img src="../../assets/images/tab-content-02.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </accordion-group>
                <accordion-group [isOpen]="false" #groupCoding>
                    <div accordion-heading>
                        <div class="float-right">
                            <span
                                [ngClass]="{'arrow-up-icon ': groupCoding?.isOpen, 'arrow-down-icon ': !groupCoding?.isOpen }"
                                class=" icon-for-accordion "></span>
                        </div>
                        <i class="fas fa-rocket "></i><span class="display-block xs-display-inline-block">Coding
                            process</span>
                    </div>
                    <div>
                        <div class="container">
                            <div class="row p-t-50">
                                <div class="col-lg-5 sm-margin-20px-bottom xs-margin-15px-bottom sm-text-center">
                                    <img src="../../assets/images/tab-content-03.png" alt="">
                                </div>
                                <div class="col-lg-5 offset-lg-1">
                                    <h5>You have a ready site</h5>
                                    <p>All websites are made responsive so they will work across various screens, from
                                        mobile to desktop. Coding is done according to our best practices.</p>

                                    <ul class="list-style no-margin-bottom">
                                        <li>One click and site ready to use</li>
                                        <li>Your business deserves best software</li>
                                        <li>Solve your problem with us</li>
                                        <li>We Provide Awesome Services</li>
                                        <li>Exclusive design</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </accordion-group>
                <accordion-group [isOpen]="false" #groupTesting>
                    <div accordion-heading>
                        <div class="float-right">
                            <span
                                [ngClass]="{'arrow-up-icon ': groupTesting?.isOpen, 'arrow-down-icon ': !groupTesting?.isOpen }"
                                class=" icon-for-accordion "></span>
                        </div>
                        <i class="far fa-chart-bar "></i><span
                            class="display-block xs-display-inline-block">Testing/Quality
                            Assurance</span>
                    </div>
                    <div>
                        <div class="container">
                            <div class="row p-t-50">
                                <div class="col-lg-5 sm-margin-20px-bottom xs-margin-15px-bottom sm-text-center">
                                    <img src="../../assets/images/tab-content-01.png" alt="">
                                </div>
                                <div class="col-lg-5 offset-lg-1">

                                    <h5>Integration of design</h5>
                                    <p>The process of testing should begin with a clear statement of the purpose of the
                                        test
                                        and the intended inferences to be made from the test scores.</p>

                                    <ul class="list-style no-margin-bottom">
                                        <li>Exclusive design</li>
                                        <li>Life time supports</li>
                                        <li>Solve your problem with us</li>
                                        <li>We Provide Awesome Services</li>
                                        <li>Your business deserves best software</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </accordion-group>
                <accordion-group [isOpen]="false" #groupDocumentation>
                    <div accordion-heading>
                        <div class="float-right">
                            <span
                                [ngClass]="{'arrow-up-icon ': groupDocumentation?.isOpen, 'arrow-down-icon ': !groupDocumentation?.isOpen }"
                                class=" icon-for-accordion "></span>
                        </div>
                        <i class="fas fa-code "></i><span class="display-block xs-display-inline-block">Documentation of
                            the project</span>
                    </div>
                    <div>
                        <div class="container">
                            <div class="row p-t-50">
                                <div class="col-lg-5 sm-margin-20px-bottom xs-margin-15px-bottom order-lg-1 order-2">
                                    <h5>Let’s build something great</h5>
                                    <p>Finally, project documentation is a vital part of our workflow to make sure that
                                        project requirements are fulfilled and to establish traceability.</p>

                                    <ul class="list-style no-margin-bottom">
                                        <li>Life time supports</li>
                                        <li>Exclusive design</li>
                                        <li>Solve your problem with us</li>
                                        <li>We Provide Awesome Services</li>
                                        <li>Your business deserves best software</li>
                                    </ul>

                                </div>
                                <div
                                    class="col-lg-5 offset-lg-1 order-lg-2 order-1 sm-text-center sm-margin-20px-bottom">
                                    <img src="../../assets/images/tab-content-02.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </accordion-group>
            </accordion>
        </div>
    </div>
</section>
<!-- end OUR WORK PROCESS Mobile section -->


<!-- start OUR WORK PROCESS Desktop section-->
<section theme class="process-desktop">
    <div class="container">
        <div class="section-heading">
            <h3>OUR WORK PROCESS</h3>
        </div>
    </div>
    <div class="container no-padding xs-padding-15px-lr">
        <div>
            <tabset>
                <tab>
                    <ng-template tabHeading>
                        <i class="far fa-chart-bar"></i>
                        <span class="display-block xs-display-inline-block">Defining the problem</span>
                    </ng-template>
                    <div>
                        <div class="container">
                            <div class="row p-t-50">
                                <div class="col-lg-5 sm-margin-20px-bottom xs-margin-15px-bottom sm-text-center">
                                    <img src="../../assets/images/tab-content-01.png" alt="">
                                </div>
                                <div class="col-lg-5 offset-lg-1">

                                    <h5>FAEFAKEFAKE</h5>
                                    <p>Initially, we clarify the priorities of the upcoming project by focusing on key
                                        critical issues. Well-defined problems lead to breakthrough solutions by our
                                        team.
                                    </p>

                                    <ul class="list-style no-margin-bottom">
                                        <li>Exclusive design</li>
                                        <li>Life time supports</li>
                                        <li>Solve your problem with us</li>
                                        <li>We Provide Awesome Services</li>
                                        <li>Your business deserves best software</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </tab>
                <tab>
                    <ng-template tabHeading>
                        <i class="fas fa-code"></i><span class="display-block xs-display-inline-block">Planning the
                            solution</span>
                    </ng-template>
                    <div>
                        <div class="container">
                            <div class="row p-t-50">
                                <div class="col-lg-5 sm-margin-20px-bottom xs-margin-15px-bottom order-lg-1 order-2">
                                    <h5>Let’s build something great FAEFAKEFAKE</h5>
                                    <p>The planning starts from identifying the goals of the project. The outcome is a
                                        documented outline, detailed project plan and a web strategy.</p>

                                    <ul class="list-style no-margin-bottom">
                                        <li>Life time supports</li>
                                        <li>Exclusive design</li>
                                        <li>Solve your problem with us</li>
                                        <li>We Provide Awesome Services</li>
                                        <li>Your business deserves best software</li>
                                    </ul>

                                </div>
                                <div
                                    class="col-lg-5 offset-lg-1 order-lg-2 order-1 sm-text-center sm-margin-20px-bottom">
                                    <img src="../../assets/images/tab-content-02.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>
                <tab>
                    <ng-template tabHeading>
                        <i class="fas fa-rocket "></i><span class="display-block xs-display-inline-block">Coding
                            process</span>
                    </ng-template>
                    <div>
                        <div class="container">
                            <div class="row p-t-50">
                                <div class="col-lg-5 sm-margin-20px-bottom xs-margin-15px-bottom sm-text-center">
                                    <img src="../../assets/images/tab-content-03.png" alt="">
                                </div>
                                <div class="col-lg-5 offset-lg-1">
                                    <h5>You have a ready site</h5>
                                    <p>All websites are made responsive so they will work across various screens, from
                                        mobile to desktop. Coding is done according to our best practices.</p>

                                    <ul class="list-style no-margin-bottom">
                                        <li>One click and site ready to use</li>
                                        <li>Your business deserves best software</li>
                                        <li>Solve your problem with us</li>
                                        <li>We Provide Awesome Services</li>
                                        <li>Exclusive design</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>
                <tab>
                    <ng-template tabHeading>
                        <i class="far fa-chart-bar "></i><span
                            class="display-block xs-display-inline-block">Testing/Quality Assurance</span>
                    </ng-template>
                    <div>
                        <div class="container">
                            <div class="row p-t-50">
                                <div class="col-lg-5 sm-margin-20px-bottom xs-margin-15px-bottom sm-text-center">
                                    <img src="../../assets/images/tab-content-01.png" alt="">
                                </div>
                                <div class="col-lg-5 offset-lg-1">

                                    <h5>Integration of design</h5>
                                    <p>The process of testing should begin with a clear statement of the purpose of the
                                        test
                                        and the intended inferences to be made from the test scores.</p>

                                    <ul class="list-style no-margin-bottom">
                                        <li>Exclusive design</li>
                                        <li>Life time supports</li>
                                        <li>Solve your problem with us</li>
                                        <li>We Provide Awesome Services</li>
                                        <li>Your business deserves best software</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </tab>
                <tab>
                    <ng-template tabHeading>
                        <i class="fas fa-code "></i><span class="display-block xs-display-inline-block">Documentation of
                            the project</span>
                    </ng-template>
                    <div>
                        <div class="container">
                            <div class="row p-t-50">
                                <div class="col-lg-5 sm-margin-20px-bottom xs-margin-15px-bottom order-lg-1 order-2">
                                    <h5>Let’s build something great</h5>
                                    <p>Finally, project documentation is a vital part of our workflow to make sure that
                                        project requirements are fulfilled and to establish traceability.</p>

                                    <ul class="list-style no-margin-bottom">
                                        <li>Life time supports</li>
                                        <li>Exclusive design</li>
                                        <li>Solve your problem with us</li>
                                        <li>We Provide Awesome Services</li>
                                        <li>Your business deserves best software</li>
                                    </ul>

                                </div>
                                <div
                                    class="col-lg-5 offset-lg-1 order-lg-2 order-1 sm-text-center sm-margin-20px-bottom">
                                    <img src="../../assets/images/tab-content-02.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>
            </tabset>
        </div>
    </div>
</section>
<!-- endOUR WORK PROCESS Desktop section -->

<app-what-our-client-say></app-what-our-client-say>