import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faSquare, faCheckSquare } from '@fortawesome/free-solid-svg-icons';

import { WhatOurClientSayComponent } from './what-our-client-say/what-our-client-say.component';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AnimatedDigitComponent } from './animated-digit/animated-digit.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { darkTheme, lightTheme, ThemeModule } from './theme';
import { ThemeBtnComponent } from './theme-btn/theme-btn.component';


@NgModule({
    declarations: [
        AppComponent,
        AboutComponent,
        HomeComponent,
        ServicesComponent,
        ContactComponent,
        WhatOurClientSayComponent,
        FooterComponent,
        HeaderComponent,
        AnimatedDigitComponent,
        ScrollTopComponent,
        ThemeBtnComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FontAwesomeModule,
        CarouselModule.forRoot(),
        TabsModule.forRoot(),
        AccordionModule.forRoot(),
        ThemeModule.forRoot({
            themes: [lightTheme, darkTheme],
            active: 'dark'
        })
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private library: FaIconLibrary) {
        library.addIcons(faSquare, faCheckSquare);
    }
}
