import { Theme } from './symbols';

export const lightTheme: Theme = {
  name: 'light',
  properties: {
    '--background': '#fff',
    '--on-background': '#000',
    '--primary': '#1976d2',
    '--on-primary': '#000',
    '--secondary': '#a8a8a8',
    '--on-secondary': '#fff',
    '--surface': '#fff',
    '--on-surface': '#000',
    '--error': '#E74E3C',
    '--on-error': '#fff',
    '--gray-for-text': '#6F6F6F',
    '--secondary-background': '#F7F7F7',
    '--secondary-border': '#ececec',
    '--for-about-scores': '#ffffff',
    '--for-footer-background': '#1F1F1F',
    '--for-footer-bar-background': '#191919',
    '--for-footer-text-color': '#939393',
    '--for-what-we-offer-item-background': '#efefef',
    '--main-color': '#4e54c8',

  }
};
