<!-- start page title section -->
<section theme class="page-title-section bg-img cover-background theme-overlay" data-overlay-dark="7">
    <div class="container">

        <div class="row">
            <div class="col-md-12">
                <h1 class="services-title">Our Services</h1>
            </div>
            <div class="col-md-12">
                <p class="services-aim">We aim to grow your business and help to take it to the next level!</p>
            </div>
        </div>

    </div>
</section>
<!-- end page title section -->

<!-- start service section -->
<section theme class="services-offers">
    <div class="container">

        <div class="row">
            <div class="col-lg-4 col-md-12 margin-30px-bottom sm-margin-20px-bottom">
                <div class="services-block padding-45px-tb padding-25px-lr sm-padding-35px-tb sm-padding-20px-lr xs-padding-30px-tb xs-padding-15px-lr last-paragraph-no-margin wow fadeInUp"
                    data-wow-delay=".2s">
                    <div class="title-box margin-25px-bottom sm-margin-15px-bottom">
                        <i class="fas fa-clone text-theme-color"></i>
                        <div class="box-circle-large"></div>
                        <div class="box-circle-small"></div>
                    </div>
                    <h3 class="margin-10px-bottom font-size22 md-font-size20 xs-font-size18">Decent & Customizable
                        Development</h3>
                    <p class="font-size16 line-height-28 sm-font-size14 sm-line-height-24">Creating web and mobile
                        services We employ UX-friendly web and mobile strategies to produce a cohesive, and desirable
                        effect on the target audience. We also make sure it is a part of your UI.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 margin-30px-bottom sm-margin-20px-bottom">
                <div class="services-block padding-45px-tb padding-25px-lr sm-padding-35px-tb sm-padding-20px-lr xs-padding-30px-tb xs-padding-15px-lr last-paragraph-no-margin wow fadeInUp"
                    data-wow-delay=".3s">
                    <div class="title-box margin-25px-bottom sm-margin-15px-bottom">
                        <i class="fas fa-code text-theme-color"></i>
                        <div class="box-circle-large"></div>
                        <div class="box-circle-small"></div>
                    </div>
                    <h3 class="margin-10px-bottom font-size22 md-font-size20 xs-font-size18">Clean & Powerful Code</h3>
                    <p class="font-size16 line-height-28 sm-font-size14 sm-line-height-24">Getting quality and
                        maintainable code It is our task to provide you with clean code. Your code will be precise,
                        sufficiently open and understandable for it to really support team work.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 margin-30px-bottom sm-margin-20px-bottom">
                <div class="services-block padding-45px-tb padding-25px-lr sm-padding-35px-tb sm-padding-20px-lr xs-padding-30px-tb xs-padding-15px-lr last-paragraph-no-margin wow fadeInUp"
                    data-wow-delay=".4s">
                    <div class="title-box margin-25px-bottom sm-margin-15px-bottom">
                        <i class="fab fa-accusoft text-theme-color"></i>
                        <div class="box-circle-large"></div>
                        <div class="box-circle-small"></div>
                    </div>
                    <h3 class="margin-15px-bottom font-size22 md-font-size20 xs-font-size18">Responsive Design</h3>
                    <p class="font-size16 line-height-28 sm-font-size14 sm-line-height-24">Optimizing your website for
                        all kinds of devices The success of any website lies on responsive web design to generate higher
                        conversions across desktop, tablet and mobile devices.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 margin-30px-bottom sm-margin-20px-bottom">
                <div class="services-block padding-45px-tb padding-25px-lr sm-padding-35px-tb sm-padding-20px-lr xs-padding-30px-tb xs-padding-15px-lr last-paragraph-no-margin wow fadeInUp"
                    data-wow-delay=".5s">
                    <div class="title-box margin-25px-bottom sm-margin-15px-bottom">
                        <i class="fas fa-cog text-theme-color"></i>
                        <div class="box-circle-large"></div>
                        <div class="box-circle-small"></div>
                    </div>
                    <h3 class="margin-10px-bottom font-size22 md-font-size20 xs-font-size18">Unique & Customizable
                        Appearance</h3>
                    <p class="font-size16 line-height-28 sm-font-size14 sm-line-height-24">Creating brand’s unified
                        entity We employ user-centered unique design to produce a cohesive and desirable effect on the
                        target audience. We also make sure it is a part of your brand.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 sm-margin-20px-bottom">
                <div class="services-block padding-45px-tb padding-25px-lr sm-padding-35px-tb sm-padding-20px-lr xs-padding-30px-tb xs-padding-15px-lr last-paragraph-no-margin wow fadeInUp"
                    data-wow-delay=".6s">
                    <div class="title-box margin-25px-bottom sm-margin-15px-bottom">
                        <i class="fas fa-paint-brush text-theme-color"></i>
                        <div class="box-circle-large"></div>
                        <div class="box-circle-small"></div>
                    </div>
                    <h3 class="margin-10px-bottom font-size22 md-font-size20 xs-font-size18">Website Design</h3>
                    <p class="font-size16 line-height-28 sm-font-size14 sm-line-height-24">Creating your design from
                        scratch Our design is based on novelties and accepted standards. Just good, honest hand-built
                        websites designed to fit your business, crafted by our full-time team.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 sm-margin-20px-bottom">
                <div class="services-block padding-45px-tb padding-25px-lr sm-padding-35px-tb sm-padding-20px-lr xs-padding-30px-tb xs-padding-15px-lr last-paragraph-no-margin wow fadeInUp"
                    data-wow-delay=".6s">
                    <div class="title-box margin-25px-bottom sm-margin-15px-bottom">
                        <i class="fas fa-star text-theme-color"></i>
                        <div class="box-circle-large"></div>
                        <div class="box-circle-small"></div>
                    </div>
                    <h3 class="margin-10px-bottom font-size22 md-font-size20 xs-font-size18">Brand Strategy</h3>
                    <p class="font-size16 line-height-28 sm-font-size14 sm-line-height-24">From logo to design details
                        Every great design project is based on brand strategy. We work with you, to determine the state
                        of your industry and market, as well as your client psychographics, in order to position your
                        brand for success.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="services-block padding-45px-tb padding-25px-lr sm-padding-35px-tb sm-padding-20px-lr xs-padding-30px-tb xs-padding-15px-lr last-paragraph-no-margin wow fadeInUp"
                    data-wow-delay=".7s">
                    <div class="title-box margin-25px-bottom sm-margin-15px-bottom">
                        <i class="fas fa-sync text-theme-color"></i>
                        <div class="box-circle-large"></div>
                        <div class="box-circle-small"></div>
                    </div>
                    <h3 class="margin-15px-bottom font-size22 md-font-size20 xs-font-size18">Regular Updates</h3>
                    <p class="font-size16 line-height-28 sm-font-size14 sm-line-height-24">Keeping your website up to
                        date With our regular updates, your website’s content will be fresh and kept current. It is
                        beneficial for your website to keep up with hot topics and search engine rankings.</p>
                </div>
            </div>
        </div>

    </div>
</section>
<!-- end service section -->